import { BFF_PATH, HOST, MONDO_PHARMA, PLP_PAGE_TYPE, SDM_PHARMA, VIEW_RESOLVER } from '@/data/constants';

export interface KeyMap {
  q: string;
  sort: string;
  page: string;
  text: string;
}

export const getContentfulPreviewHeaders = (query: { preview?: 'true'; dateTime?: string }): Record<string, string> => {
  const headers: Record<string, string> = {
    'x-preview': query.preview || 'false',
    'x-preview-datetime': query.dateTime ?? new Date().toISOString(),
  };

  return headers;
};

export const getSlugFromUrl = (url: string): string => {
  const { HOME_PAGE } = VIEW_RESOLVER;

  const queryStringIndex = url.indexOf('?');
  const pathname = queryStringIndex !== -1 ? url.slice(0, queryStringIndex) : url;

  if (pathname === '/') {
    return `/${HOME_PAGE}`;
  }

  const trimmedPathname = pathname.replace(/\/$/, '');

  return trimmedPathname || HOME_PAGE;
};

export const getKeyIdFromUrl = (url: string): string => {
  if (url.startsWith('/search')) {
    const queryString = url.split('?')[1];
    const queryParams = queryString.split('&').reduce((params: Record<string, string>, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
    return queryParams['text'] ?? '';
  }

  const queryStringIndex = url.indexOf('?');
  const pathname = queryStringIndex !== -1 ? url.slice(0, queryStringIndex) : url;

  if (pathname.startsWith('/shop/')) {
    const [, keyId] = pathname.split('/c/');
    return keyId;
  }

  return '';
};

export const decodeUrlParams = (input: string): string => {
  let decodedString: string = input.replace(/%25/g, '%');
  decodedString = decodeURIComponent(decodedString);

  return decodedString;
};

export const getBffPath = (pageType: string, keyId: string): string => {
  const { CATEGORY, SEARCH, BRAND, COLLECTION, PREVIOUS_PURCHASE, FAVOURTIE } = PLP_PAGE_TYPE;
  let bffUrlPrefix = '';

  switch (pageType) {
    case CATEGORY:
    case COLLECTION:
      bffUrlPrefix = BFF_PATH.CATEGORY;
      break;
    case BRAND:
      bffUrlPrefix = BFF_PATH.BRAND;
      break;
    case SEARCH:
      bffUrlPrefix = BFF_PATH.SEARCH;
      break;
    case PREVIOUS_PURCHASE:
      bffUrlPrefix = BFF_PATH.PREVIOUS_PURCHASE;
      break;
    case FAVOURTIE:
      bffUrlPrefix = BFF_PATH.FAVOURTIE;
      break;
    default:
      throw new Error(`Invalid page type: ${pageType}`);
  }

  return `${bffUrlPrefix}${keyId}`;
};

export const getRefillHref = (locale: string, isPhxBanner: boolean) => {
  if (isPhxBanner) {
    return `${MONDO_PHARMA}/${locale}/Account/LogIn`;
  }
  return `${SDM_PHARMA}/${locale}`;
};

export const getStoreOrFlyerHref = (locale: string, storeOrFlyer: string, isPhxBanner: boolean) => {
  if (isPhxBanner) {
    return `${HOST.PHX_HOME}/${locale}/${storeOrFlyer}`;
  }
  return `${HOST.SDM_HOME}/${locale}/${storeOrFlyer}`;
};

export const getVariantOrBaseCode = (product: any) => {
  // Check if variantCode is present
  if (product.variantCode) return product.variantCode;

  // Extract slug[2] and remove "BB_" if it exists
  const slugCode = product.slug.length > 2 ? product.slug[2] : product.slug[1];
  if (slugCode.startsWith('BB_')) return slugCode.replace('BB_', '');

  // If both condition fails, return slug code
  return slugCode;
};
