import { useMemo } from 'react';
import { isBrowser, recursivelyUpdateField } from 'Components/page-seo-meta/page-seo-meta-helpers';

/**
 * Parsed the structured data from the page meta seo context.
 */
export const useStructuredDataForSeo = (jsonLd: string | undefined, baseUrl: string): null | string => {
  const parsedData = useMemo(() => {
    if (!jsonLd) {
      return null;
    }
    try {
      const parsed = JSON.parse(jsonLd);
      // Update data with the url field
      recursivelyUpdateField(
        'url',
        (value) => {
          if ((value as string).includes('https://')) {
            return value;
          } else {
            return `${baseUrl}${value as string}`;
          }
        },
        parsed
      );
      if (isBrowser()) {
        parsed.url = window.location.href;
      } else {
        parsed.url = baseUrl;
      }
      return JSON.stringify(parsed);
    } catch (e) {
      return null;
    }
  }, [jsonLd, baseUrl]);
  return parsedData;
};

export default useStructuredDataForSeo;
