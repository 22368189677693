import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

export const useViewportType = () => {
  const [viewportType, setViewportType] = useState('mobile');

  const handleResize = throttle(() => {
    const breakpoints = { desktop: 992, tablet: 768 };
    const { innerWidth } = window;
    if (innerWidth >= breakpoints.desktop) {
      setViewportType('desktop');
    } else if (innerWidth >= breakpoints.tablet) {
      setViewportType('tablet');
    } else {
      setViewportType('mobile');
    }
  }, 250);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return viewportType;
};
