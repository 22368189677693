import en from './en.json';
import fr from './fr.json';

const languages = {
  en,
  fr,
};

export type Locale = 'en' | 'fr';

export default languages;
