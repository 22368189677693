import { useState, useEffect } from 'react';
import { EventName } from '@sdm/analytics-helper-library';
import { IPCOptimumAccount } from '@ldp/sdm-contexts';

import { useWindowSize, useDetermineLocale } from '@/hooks';
import { mapPageIdToPageName, hasExistingEvent, trackSnowplow } from '@/utils/analytics/analytics-utils';
import { getGuid, isLoggedIn } from '@/utils/auth';

export const useTrackPageView = (
  cartCount: number,
  pageId: string,
  pcOptimumAccount: IPCOptimumAccount,
  isInitialCartCountUpdated: boolean
) => {
  const { currentDevice } = useWindowSize({ isModernized: true });
  const locale = useDetermineLocale();
  const pageName = mapPageIdToPageName[pageId] || pageId;
  const guid = getGuid();
  const loggedInStatus = !!isLoggedIn();

  //states
  const [shouldTriggerPageView, setShouldTriggerPageView] = useState(false);
  const [hasPageViewFired, setHasPageViewFired] = useState(
    hasExistingEvent(EventName.PageView, 'page.page_name', pageName)
  );

  useEffect(() => {
    // case for non logged in user with no guid
    if (!shouldTriggerPageView && !guid && !loggedInStatus) {
      setShouldTriggerPageView(true);
    }

    // case for users with guid - ensure cart count is updated before firing pageView
    if (!shouldTriggerPageView && isInitialCartCountUpdated && pcOptimumAccount !== null) {
      setShouldTriggerPageView(true);
    }
  }, [shouldTriggerPageView, isInitialCartCountUpdated, pcOptimumAccount, guid, loggedInStatus, pageId]);

  useEffect(() => {
    if (pageId && !hasPageViewFired && shouldTriggerPageView) {
      trackSnowplow(
        {
          event: EventName.PageView,
        },
        { cartCount, locale, currentDevice, pageId, pcOptimumAccount }
      );
      setHasPageViewFired(hasExistingEvent(EventName.PageView, 'page.page_name', pageName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, hasPageViewFired, shouldTriggerPageView]);
};
