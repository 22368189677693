export const ROUTES = {
  HOME_PAGE: '/',
  SHOPPING_BAG: '/shoppingbag',
  CHECKOUT_PAGE: '/purchase',
  LOGIN: '/login',
  SIGN_UP: '/signup',
  YOU_CAM: '/c/FS-SkinCare',
  YOU_CAM_MAIN_QUERY: '?nav=/shop/Categories/SkinCare&sort=trending&page=0&q=',
  SIGN_IN_ERROR: '/messages/sign-in-error',
  BEAUTY_LANDING_PAGE: '/Beauty',
  ORDER_CONFIRMATION: '/confirmation',
} as const;

export const AUTH_OPERATIONS = ['setProductFavouriteStatus', 'GetFavouriteListData'] as const;

// These are operations where if a user WAS logged in, we want to redirect them to login by checking to see if their CIAM access token is valid
export const AUTH_AND_GUEST_OPS = [
  'addToCart',
  'CreateCartShippingAddress',
  'GetUser',
  'setGuestEmail',
  'GetCart',
  'CartSetAddress',
  'cart',
  'SetDeliveryModeById',
  'editUserAddress',
  'userData',
] as const;
