import { useRef } from 'react';
import { useRouter } from 'next/router';
import Datepicker from '../components/datepicker';

export const useContentfulPreview = () => {
  const router = useRouter();
  const { preview: isContentfulPreview = false, dateTime } = router.query as { preview: string, dateTime?: string };
  const inputElement = useRef<HTMLInputElement>(null);
  const selectedDateTime: string = dateTime ?? new Date().toISOString();

  const handleButtonClick = () => {
    const updatedTime = new Date(inputElement.current!.value).toISOString();
    window.location.href = `?preview=true&dateTime=${updatedTime}`;
  }

  const renderDatePicker = () => (
    <Datepicker
      selectedDateTime={selectedDateTime}
      refElement={inputElement}
      onClick={handleButtonClick}
    />
  );

  return {
    isContentfulPreview,
    renderDatePicker,
    selectedDateTime
  };
}
