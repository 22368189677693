import { useEffect } from 'react';

export const useUpdateAppBannerStyles = () => {
  useEffect(() => {
    const bannerElement = document.querySelector('.showSdmAppBanner');

    if (!bannerElement) return;

    const updateElements = (isAtTop: boolean) => {
      const topBarElement = document.querySelector('div[data-testid="top-bar"]') as HTMLElement | null;
      const spacerElement = document.querySelector('div[data-test-id="Spacer"]') as HTMLElement | null;

      if (topBarElement && spacerElement) {
        const parentElement = topBarElement?.parentElement as HTMLElement | null;

        if (parentElement) {
          parentElement.style.position = isAtTop ? 'relative' : '';
          spacerElement.style.height = isAtTop ? '75px' : '';
        }
      }
    };

    const handleScroll = () => {
      const isAtTop = window.scrollY === 0;
      updateElements(isAtTop);
    };

    window.addEventListener('scroll', handleScroll);

    const timer = setTimeout(() => {
      updateElements(true);
    }, 1500); // this timer is needed for the DOM to load

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};
