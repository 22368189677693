import { useMemo } from 'react';
import { IViewDefinition } from '@/components/authored-page-routes/authored-page-routes.interface';

export const PRODUCT_GRID_COMPONENT_ID = 'productGridComponent';

/**
 * Note:
 * The logic contained is meant to be limited to this single file
 * in order to more easily deprecate these functionalities.
 *
 * The purpose is to fix the layout of the grid on page-load,
 * which will break any grid containing row-overrides on layouts
 * other than a 4 column grid layout.
 * Once SDUI is able to solution appropriate row-override
 * placements on page load (and hopefully on resize) for SSR apps
 * this file and its changes can be safely removed.
 **/
const enum MATH_TYPE {
  SUB,
  ADD,
}

// where 'key' represents the lowest possible screen width value for $tiles per row
export interface IViewportConfig {
  [key: number]: { tiles: number };
}

export interface IMainContentCollection extends Record<string, unknown> {
  alignment: string;
  components: Array<any>;
}

export interface IComponentBase extends Record<string, unknown> {
  componentId: string;
  contentId: string;
  id: string;
  data: unknown;
}

export interface IProductGridData extends Record<string, unknown> {
  pagination: unknown;
  productTiles: Array<IProductTile>;
}

export interface IProductGrid extends Omit<IComponentBase, 'data'> {
  data: IProductGridData;
}

export type IComponents = IProductGrid | IComponentBase;

export interface IProductTile extends Record<string, unknown> {
  isSponsored: boolean;
}

export interface IProductGridComponent extends Omit<IComponentBase, 'data'> {
  data: {
    pagination: unknown;
    productTiles: Array<IProductTile>;
  };
}

// if screen width is equal to or greater than key value, expect x tiles per row
// we hard code this here for the same reason that these functions are necessary:
// SDUI does not support an SSR application, and we cannot send the screenWidth to determine these number remotely
// before the client run-time.
// so, they must be hard-coded here.
// Note: these numbers are not accurate reflections for WEB ON MOBILE.
// As of now, they only portray the OSX Chrome desktop experience.
// Considering the difference in web experiences, this too may be a flaw to be addressed with SDUI later.
export const ProductGridViewportConfig: IViewportConfig = {
  [1122]: {
    tiles: 4,
  },
  [1024]: {
    tiles: 3,
  },
  [1005]: {
    tiles: 5,
  },
  [992]: {
    tiles: 4,
  },
  [990]: {
    tiles: 7,
  },
  [827]: {
    tiles: 6,
  },
  [697]: {
    tiles: 5,
  },
  [566]: {
    tiles: 4,
  },
  [436]: {
    tiles: 3,
  },
  [305]: {
    tiles: 2,
  },
  [0]: {
    tiles: 1,
  },
};

/**
 * getAndRemoveSponsoredSlots
 * The functions of media (sponsored) components necessitates that they remain in their visible designated slot
 * CURRENTLY (08/09/2024 : DD/MM/yyyy) SDM proffers sponsored ads at final index of each row
 * ie. if there are 2 sponsored tiles on a 4 column grid, we should expect those sponsored tiles to appear
 * at indexes 3 and 7.
 * Conversely, if these same sponsored tiles were on a 3 column grid, we should expect them
 * at indexes 2 and 5.
 *
 * While readjusting the grid itself is no pretty thing, the manner of removing and replacing the sponsored tiles
 * of their own accord make it doubly ugly, so comments have been provided to try to clarify the goings ons
 **/
export const getAndRemoveSponsoredSlots = (component: IProductGrid): Array<IProductTile> => {
  let sponsoredTiles: Array<IProductTile> = [];

  for (let i = 0; i < component.data.productTiles.length; i++) {
    const tile: IProductTile = component.data.productTiles[i];
    if (tile && tile?.isSponsored) {
      sponsoredTiles.push(tile);
      component.data.productTiles.splice(i, 1);
    }
  }

  return sponsoredTiles;
};

/**
 * Get the expected number of tiles per row for the active screenWidth
 **/
export const getTilesForScreenSize = (screenWidth: number): number => {
  const minRanges: Array<number> = Object.keys(ProductGridViewportConfig).map((key) => parseInt(key));
  let tiles = 0;
  for (let i = minRanges.length - 1; i > -1 && !tiles; i--) {
    if (screenWidth >= minRanges[i]) {
      tiles = ProductGridViewportConfig[minRanges[i]].tiles;
    }
  }

  return tiles;
};

/**
 * manipulateGridForRowOverrideFix: the bulk of our cursed manifestations
 * herein we loop several times through the components in order to both take and give tiles
 * to subsequent grid instances within the array.
 *
 * The concept is that we know SDUI will always return a 4 column layout
 * (please refer to https://coda.io/d/_dW2PUUVTvFS/SDM-Contenful-Rich-Product-Grid-Guidelines_su2xY#_luK9i)
 *
 * and so for layouts less than 4 columns, we must be prepared to take from our starting grid and give excess
 * tiles to the next, and so on until the expected number of of tiles trickles into the last grid instance
 *
 * for layouts larger than 4, we must be prepared to do the opposite.
 * we evaluate how many tiles a grid is lacking, and then pilfer those tiles from the next grid (and possibly the one after that)
 * until we have satisfaction. when we work on the next instance of the grid, we will find that
 * this loop of grid theft continues on. It is necessary to include these seemingly redundant loops of stealing and appending
 * due to needing to preserve the order of tiles that are returned.
 * This is also why extra annoyances need to be handled for sponsored tiles
 **/
export const manipulateGridForRowOverrideFix = (components: Array<IComponents>, tilesPerRow: number) => {
  const componentsCopy = JSON.parse(JSON.stringify(components));
  let oldGridTilesCount = 0, // we keep track of the origin of tiles that we have gone through
    tileCount = 0; // all tiles we have manipulated as they reach our active grid instance

  for (let i = 0; i < componentsCopy.length; i++) {
    // we aren't concerned with anything other than grids
    if (componentsCopy[i].componentId === PRODUCT_GRID_COMPONENT_ID) {
      // first, we must determine how many rows are meant to be in this grid.
      // that means taking the returned tile array and dividing by 4
      // (because we know we will be getting an assumed 4 column layout)
      // this number is our tile multiplier.
      // if we recieve 8 tiles in the grid instance, 8/4 = 2.
      // we know that no matter what, we need 2 rows worth of tiles in this grid.
      // this is why we are using our original copy `components` rather than
      // our monster `componentsCopy`.
      // `componentsCopy` will be subject to various horrors through the process,
      // but `components` will always preserve the memory of how the grid arrived
      const rowsInGrid = (components[i] as IProductGrid).data.productTiles.length / 4;

      // then we extract all the sponsored tiles from the grid instance
      // and store them for later
      const sponsoredTiles = getAndRemoveSponsoredSlots(componentsCopy[i]);

      // calculating the new total number of tiles after extraction
      let localTiles = (componentsCopy[i] as IProductGridComponent).data.productTiles.length;
      // update the total count!
      tileCount += localTiles;

      // we aren't interested in performing these manipulations on the final grid.
      // it gets all the leftovers anyways
      if (i !== components.length - 1) {
        // to determine what index to stop having tiles at
        // we take the number of rows we know our current grid to have,
        // and include the previously accounted for tiles.
        // (tileCount is a bit _too_ up to date to use at this point)
        const intendedTileIndexBreak = rowsInGrid * tilesPerRow + oldGridTilesCount;

        // this ambigious number will give us a negative or positive value.
        // we take the index we know we need, and subtract the totalTiles we have so far
        // we must also subtract the amount of sponsoredTiles removed, as they will be returned at the end
        // (and we don't want to accidentally give/take more tiles than we mean to!)
        let additionalTilesRequired = intendedTileIndexBreak - sponsoredTiles.length - tileCount;
        const mathType = additionalTilesRequired > 0 ? MATH_TYPE.ADD : MATH_TYPE.SUB;
        // once we know if we're adding or subtracting ^, we absolute the value
        additionalTilesRequired = Math.abs(additionalTilesRequired);

        /**
         * Below are the core mathematics of butchering arrays to suit our needs.
         * We know that any tiles pulled will be appended or prepended in chronological order
         * to our array, so we can store them in pulledTiles to do so at the end of our loops
         **/

        if (mathType === MATH_TYPE.ADD) {
          /**
           * ADDING
           * if we get to here, it means that we need more tiles in the current grid than what it has
           * so we're going to steal from other grids
           **/

          // we could put these vars at the top, but we don't need to hold onto them outside of this block
          let pulledTiles: Array<IProductTile> = [],
            tallyCompleted = false;

          // we start at the next component in the array
          for (let j = i + 1; !tallyCompleted && j < components.length; j++) {
            if (componentsCopy[j].componentId === PRODUCT_GRID_COMPONENT_ID) {
              const grid = componentsCopy[j] as IProductGridComponent;
              const purgedIndexes: Array<number> = [];

              // if it's a grid component
              // we're going to keep pulling non-sponsored tiles from it until there's no more to grab.
              // If the length of tiles we've pulled doesn't match the amount we need,
              // we'll move on to the next available grid instance and pull from there
              for (let k = 0; !tallyCompleted && k < grid.data.productTiles.length; k++) {
                if (grid.data.productTiles[k] && !grid.data.productTiles[k].isSponsored) {
                  pulledTiles.push(grid.data.productTiles[k]);
                  purgedIndexes.push(k);
                }

                // once we reach our target number of tiles, we can consider this done
                if (pulledTiles.length === additionalTilesRequired) {
                  // this can be simplified in the loop statement,
                  // but this is a bit easier to read and debug
                  tallyCompleted = true;
                }
              }

              // this step must be performed copying tiles over to prevent
              // premature exiting due to length/index mutations
              let count = 0;
              purgedIndexes.forEach((index) => {
                // data manipulations are wild,
                // and this is necessary for null index cleanup
                grid.data.productTiles.splice(index - count, 1);
                count++;
              });
            }
          }

          // now we add the stolen tiles to our current grid instance
          componentsCopy[i].data.productTiles.push(...pulledTiles);
          // and increase our counts to account for the newly added tiles
          oldGridTilesCount = tileCount += pulledTiles.length;
        } else {
          /**
           * SUBTRACTING
           * if we get to here, it means that the current grid has too many tiles
           * so we're going to offload the excess to the next available grid instance
           **/

          // we could put these vars at the top, but we don't need to hold onto them outside of this block
          let pulledTiles: Array<IProductTile> = [],
            tallyCompleted = false;

          // we don't need to do much looping here.
          // right away we can snag those excess tiles from our active grid (i)
          pulledTiles.push(
            ...componentsCopy[i].data.productTiles.splice(
              (componentsCopy[i] as IProductGridComponent).data.productTiles.length - additionalTilesRequired,
              (componentsCopy[i] as IProductGridComponent).data.productTiles.length
            )
          );
          // this loop is solely to find the next available grid instance
          // to dump our pulled tiles into
          for (let j = i + 1; !tallyCompleted && j < components.length; j++) {
            if (components[j].componentId === PRODUCT_GRID_COMPONENT_ID) {
              const grid = componentsCopy[j] as IProductGridComponent;
              // append the tiles to the end of this other grid
              grid.data.productTiles.unshift(...pulledTiles);
              tallyCompleted = true;
            }
          }
          // now we actually decrease the count by the number of tiles pulled.
          // it's the other guy's problem now!
          oldGridTilesCount = tileCount -= pulledTiles.length;
        }
      }

      // at the VERY END of our primary loop (remember, "i"?)
      // we finally give back those sponsored tiles we took.
      // Since we will always get at least 1 sponsored tile back per row (if applicable)
      // we don't need to do anything crazy like worry about donating or stealing sponsored tiles from other grids
      // as long as we extract them from the beginning
      // and avoid stealing them from other grids
      // we will always have the right number of sponsored tiles to return in this array
      if (sponsoredTiles.length > 0) {
        const tiles = sponsoredTiles.length;
        for (let j = 0; j < tiles; j++) {
          const slotIndex = (j + 1) * tilesPerRow - 1;
          (componentsCopy[i] as IProductGrid).data.productTiles.splice(
            slotIndex,
            0,
            sponsoredTiles.shift() as IProductTile
          );
          // and now we increase the count again for our freshly re-added sponsored tiles!
          tileCount++;
          oldGridTilesCount++;
        }
      }
    }
  }

  // we finally return the super safe copy we made!
  return componentsCopy;
};

/**
 * getProductGridDetails
 * we're really just getting the index of the first grid instance
 * and the index of the last grid instance
 * in the returned mainContentCollection array.
 * Why?
 * We can assume that anything outside of these indexes
 * is none of our business (grid and row-override related business)
 **/
export const getProductGridDetails = (components: Array<IComponents>): { startIndex: number; endIndex: number } => {
  let startIndex = -1,
    endIndex = -1;

  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (component.componentId === PRODUCT_GRID_COMPONENT_ID) {
      startIndex = startIndex === -1 ? i : startIndex;
      endIndex = i;
    }
  }

  return { startIndex, endIndex };
};

export const getUpdateMainContentForGrid = (componentCollection: Array<IComponents>) => {
  const tilesPerRow = getTilesForScreenSize(window.innerWidth);

  // 4 tiles per row is already handled right off the bat
  // and obviously we don't need to do anything if there's no components available
  if (tilesPerRow === 4 || !componentCollection || componentCollection.length < 1) return componentCollection;

  const { startIndex, endIndex } = getProductGridDetails(componentCollection);

  // if no product grids, do not proceed
  if (startIndex === -1) {
    return componentCollection;
  }

  // we make a super safe copy of our componentCollection
  const componentCollectionCopy = JSON.parse(JSON.stringify(componentCollection));
  // and then we make a copy of that
  let newGrid: Array<IComponents> = JSON.parse(JSON.stringify(componentCollectionCopy));

  // we carve out the grid range from the first copy
  const gridAndOverrideComponentsSection: Array<IComponents> = [
    ...componentCollectionCopy.slice(startIndex, endIndex + 1),
  ];

  // and splice it back into our newGrid copy once it's been modified
  newGrid.splice(
    startIndex,
    endIndex + 1,
    ...manipulateGridForRowOverrideFix(gridAndOverrideComponentsSection, tilesPerRow)
  );

  return newGrid;
};

// then we wrap up everything we just did in a deceptively simple looking
// use function...
export const useMainContentWithGrid = (viewDefinition: IViewDefinition) => {
  let newContent: IViewDefinition;
  let mainContentComponents: IComponents[];

  if (viewDefinition) {
    newContent = JSON.parse(JSON.stringify(viewDefinition));
    mainContentComponents = newContent?.layout?.sections?.mainContentCollection?.components;
  }

  const updatedContent = useMemo(() => {
    if (!mainContentComponents) return viewDefinition;
    newContent.layout.sections.mainContentCollection.components = getUpdateMainContentForGrid(mainContentComponents);
    return newContent;
  }, [viewDefinition, getUpdateMainContentForGrid]);
  return updatedContent;
};
