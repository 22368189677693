import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from 'Api/constants';
import client from 'Api/datasources/bff-datasources';
import { getExistingCartGuid, getLoggedInStatus, isLoggedIn } from 'Utils/auth';

export const updateCartQuantity = async (productId: any, quantity: any) => {
  const userStatus = () => getLoggedInStatus().toLowerCase();
  const cartUrlParam = isLoggedIn() ? userStatus() : getExistingCartGuid();
  const cartUrl = `/users/${userStatus()}/carts/${cartUrlParam}`;
  if (quantity) {
    const response = await client.patch(`${cartUrl}/entries?productCode=${productId}&qty=${quantity}`);
    return { success: response.status === 200 };
  }
  const response = await client.delete(`${cartUrl}/entries?productCode=${productId}`);
  return { success: response.status === 200 };
};

export const useUpdateCartItemQuantity = () => {
  const queryClient = useQueryClient();
  const {
    mutate: updateCartItemQuantityMutation,
    isPending: isUpdateCartItemQuantityLoading,
    error: updateCartItemQuantityError,
    data,
  } = useMutation({
    mutationFn: ({ productId, quantity }: { productId: any; quantity: any }) => updateCartQuantity(productId, quantity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CART] });
    },
  });

  const createUpdateCartItemQuantityMutation = (productId: any, quantity: any) =>
    updateCartItemQuantityMutation({ productId, quantity });

  return { createUpdateCartItemQuantityMutation, isUpdateCartItemQuantityLoading, updateCartItemQuantityError, data };
};
