import { HTTPError } from '@/utils/error';
import { environment } from '@/data/constants';

/** @throws {HTTPError} if the the http response is not ok */
export const getSdmWebTokens = async (): Promise<object> => {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {},
    body: null,
  };
  const { PRODUCTION, DEVELOPMENT, LOWER } = environment;

  const env = process.env.NODE_ENV === DEVELOPMENT ? LOWER : PRODUCTION;

  const response = await fetch(
    `https://assets-platform.loblaws.ca/ldp-design-token/tokens/${env}/latest/sdm-web/sdm-web.tokens.json`,
    requestOptions
  );

  if (!response.ok) {
    throw new HTTPError(response.status, await response.text(), { cause: { request: requestOptions, response } });
  }

  return await response.json();
};
