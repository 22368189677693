import React, { FC } from 'react';

import { IGlobalState } from './global-state.interface';
import { useStore } from '@/store/';

const GlobalState: FC<IGlobalState> = ({ children, cartData, viewDefinitionResults, kamFlags }) => {
  const updateCartData = useStore(({ setCartData }) => setCartData);
  const updateViewDefinition = useStore(({ setViewDefinition }) => setViewDefinition);
  const updateKamFlags = useStore(({ setKamFlags }) => setKamFlags);

  updateCartData(cartData!);
  updateViewDefinition(viewDefinitionResults!);
  updateKamFlags(kamFlags || null);

  return <>{children}</>;
};

export default GlobalState;
