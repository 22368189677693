import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { LOCALE, cookies, INTL_LOCALE } from '@/data/constants';
import { isPharmaprixBanner } from '@/utils';

const isPharmaprix = typeof window !== 'undefined' ? isPharmaprixBanner() : null;
const { USER_PREFERRED_LOCALE, SDM_LANG, CONTENTFUL_SDM_LANG_CODE } = cookies;

const setPreferredLanguage = (lang: string) => {
  const { EN, FR, EN_QC, FR_QC } = INTL_LOCALE;

  let sdmLang;
  if (isPharmaprix) {
    sdmLang = lang === LOCALE.EN ? EN_QC : FR_QC;
  } else {
    sdmLang = lang === LOCALE.EN ? EN : FR;
  }

  Cookies.set(USER_PREFERRED_LOCALE, lang);
  // The following two cookies are for SDM.ca pages
  Cookies.set(SDM_LANG, sdmLang);
  Cookies.set(CONTENTFUL_SDM_LANG_CODE, sdmLang);
};

export const useDetermineLocale = (): ValueOf<typeof LOCALE> => {
  const { EN, FR } = LOCALE;
  const {
    query: { lang },
  } = useRouter();

  if (lang === EN || lang === FR) {
    setPreferredLanguage(lang);
    return lang;
  }

  const preferredLanguage = Cookies.get(USER_PREFERRED_LOCALE);
  const defaultLanguage = isPharmaprix ? FR : EN;

  const islanguageCookieSet = !!Cookies.get(SDM_LANG) && !!Cookies.get(CONTENTFUL_SDM_LANG_CODE);

  if (preferredLanguage === EN || preferredLanguage === FR) {
    if (!islanguageCookieSet) {
      setPreferredLanguage(preferredLanguage);
    }
    return preferredLanguage;
  } else {
    setPreferredLanguage(defaultLanguage);
    return defaultLanguage;
  }
};
