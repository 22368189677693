import { getEnvironment } from '@/utils';
import { useEffect, useState } from 'react';

export const useDetermineEnvironment = () => {
  const [environment, setEnvironment] = useState<number>(0);

  useEffect(() => {
    const env = getEnvironment();
    setEnvironment(env);
  }, []);

  return environment;
};
