export const BFF_UAT_URL = 'https://uat-sdm-bff.api.loblaw.digital/beauty/v2/shoppersdrugmart' as const;

export const REQUEST_HEADERS = {
  X_CORRELATION_ID: 'X-Correlation-ID',
  X_LCL_CLIENTNAME: 'X-LCL-Clientname',
  REQUEST_ID: 'requestId',
  LANGUAGE: 'language',
  AUTHORIZATION: 'Authorization',
  ACCEPT: 'accept',
  ALL: '*/*',
  BEARER: 'Bearer',
  COOKIE: 'Cookie',
  X_LCL_APIKEY: 'x-lcl-apikey',
  X_DRX_JWT: 'x-drx-jwt',
  X_DRX_CSRF_TOKEN: 'x-drx-csrf-token',
  X_DRX_CLIENT: 'x-drx-client',
  X_DRX_LCL_API_KEY: 'x-drx-lcl-apikey',
  X_DRX_BANNER: 'x-drx-banner',
  X_DRX_CSRF_COOKIE_TOKEN: 'x-drx-cookie-csrf-token',
  X_DRX_CLIENT_WEBAPP: 'WEBAPP',
  LANGUAGE_EN: 'en',
  ACCEPT_LANGUAGE: 'Accept-Language',
  X_CSRF_TOKEN: 'X-CSRF-TOKEN',
  CONTENT_TYPE: 'Content-Type',
  APPLICATION_JSON: 'application/json',
  X_DRX_API_VERSION: 'x-drx-api-version',
  X_DRX_SECURITY_QUESTION_TOKEN: 'x-drx-security-question-token',
  TRUE_CLIENT_IP: 'true-client-ip',
  X_FORWARDED_FOR: 'x-forwarded-for',
  REFERER: 'referer',
  USER_AGENT: 'user-agent',
  X_LOBLAW_USER_AGENT: 'X-Loblaw-User-Agent',
  X_CLIENT_ID: 'x-client-id',
  FORCE_HELIOS: 'forcehelios',
  B3: 'b3',
  HRE_COOKIE: '_spvid_id.02ef',
  X_LOBLAW_IP_ADDRESS: 'X-Loblaw-Ip-Address',
  SNOWPLOW_USER_ID: 'snowplow-userid',
  X_APIKEY: 'x-apikey',
  ACCESS_TOKEN: 'Accesstoken',
} as const;

export const QUERY_KEYS = {
  ORDER_HISTORY: 'orderHistory',
  ORDER: 'order',
  USER_SHIPPING_ADDRESSES: 'userShippingAddresses',
  CART: 'cart',
  PWP_ELIGIBILITY: 'pwpEligibility',
  SAMPLES: 'samples',
  DELIVERY_MODES: 'deliveryModes',
  SAVED_PAYMENTS: 'savedPayments',
} as const;
