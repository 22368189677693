import { useStore } from '@/store/useStore';
import { ISDMCustomerContext, useSDMCustomerContext } from '@ldp/sdm-contexts';
import { useEffect } from 'react';
import { isLoggedIn } from '../utils/auth';

const useGetUserPcoData = () => {
  const { setPcOptimumAccount } = useSDMCustomerContext() as ISDMCustomerContext;
  const cartData = useStore(({ cartData }) => cartData);
  const loggedInStatus = !!isLoggedIn();

  useEffect(() => {
    let pcOptimumData;
    if (loggedInStatus) {
      const optimumAccountInfo = cartData?.data?.optimumAccountInfo;
      const pcoMemberId = optimumAccountInfo?.loyaltyMemberId ?? '';
      pcOptimumData = {
        memberId: pcoMemberId,
        householdId: '',
        walletId: pcoMemberId,
        points: {
          balance: optimumAccountInfo?.optimumCardBalancePoints || 0,
          dollarsRedeemable: optimumAccountInfo?.redeemableForHousehold || 0,
          dollarsRedeemedLifetime: 0,
        },
      };
    } else {
      pcOptimumData = {
        memberId: '',
        walletId: '',
        householdId: '',
        points: {
          balance: 0,
          dollarsRedeemable: 0,
          dollarsRedeemedLifetime: 0,
        },
      };
    }

    setPcOptimumAccount(pcOptimumData);
  }, [loggedInStatus]);
};

export default useGetUserPcoData;
