// @ts-nocheck
import { useMemo, useState } from 'react';

import { ISDMContextsProps } from '@ldp/sdm-contexts';

import { PhxProvince, SdmProvince } from '@/data/constants';
import { useViewportType } from '@/hooks';
import { getLoggedInUserInfo } from 'Utils/auth';
import { customerDataDefault, customerDataMock, isPharmaprixBanner } from '../utils';

export type CartEntries = ISDMContextsProps['sdmCartProviderProps']['entries'];
/**
 * Hook to retrieve the SDM contexts props.
 */
let selectedProduct = {};
export const useSDMContextProps = () => {
  const [entries, setEntries] = useState<CartEntries>({});
  const [isUpdateAction, setIsUpdateAction] = useState(false);
  const [isInitialCartCountUpdated, setIsInitialCartCountUpdated] = useState(false);
  const [pageId, setPageId] = useState('');
  const [pcOptimumAccount, setPcOptimumAccount] = useState(null);
  const defaultProvince = isPharmaprixBanner() ? PhxProvince.QC : SdmProvince.ON;
  const [provinceId, setProvinceId] = useState(defaultProvince);
  const viewport = useViewportType();
  const ciamToken = getLoggedInUserInfo();
  const pcid = ciamToken?.pcid ?? '';

  return useMemo<ISDMContextsProps>(
    () => ({
      sdmCartProviderProps: {
        /** Mocked cart ID */
        id: customerDataMock.cartId || '',
        /** Mocked cart entries */
        entries,
        /** Mocked add to cart */
        addToCart: (items: any) => {
          setIsUpdateAction(false);
          setEntries((prevEntries: any) => {
            const newEntries = { ...prevEntries };
            const { productName, productCode, quantity, pricingUnits, fulfillmentMethod } = items;
            /** If the entry already exists, and quantity is greater than 0, update the quantity */
            if (quantity > 0) {
              newEntries[productCode] = {
                productName,
                productCode,
                quantity,
                pricingUnits,
                fulfillmentMethod,
              };
            } else {
              /** If the entry already exists, and quantity is 0, remove it */
              delete newEntries[productCode];
            }
            if (typeof prevEntries[items.productCode] !== 'undefined') {
              if (prevEntries[items.productCode].quantity > 0) {
                setIsUpdateAction(true);
              }
            }
            selectedProduct = items;
            return newEntries;
          });
        },
        /** Mocked remove from cart */
        removeFromCart: (items) => {
          setEntries((prevEntries) => {
            /** If the entry already exists, remove it */
            const newEntries = { ...prevEntries };
            Object.keys(items).forEach((key) => {
              delete newEntries[key];
              setIsUpdateAction(false);
            });
            selectedProduct = items;
            return newEntries;
          });
        },
        offerType: 'OG',
        storeId: '1028',
        pickupType: 'STORE',
        isLoading: false,
        selectedProduct,
        isUpdateAction,
        populateEnteries: (cart: any) => {
          setEntries(cart);
        },
        isInitialCartCountUpdated,
        setIsInitialCartCountUpdated,
        pageId,
        setPageId,
      },
      sdmCustomerProviderProps: {
        ...customerDataDefault,
        ...(pcid && { customerId: pcid }),
        setPcOptimumAccount,
        pcOptimumAccount,
        provinceId,
        setProvinceId,
      },
      sdmViewportProviderProps: {
        viewport,
      },
    }),
    [entries, setEntries, isUpdateAction, isInitialCartCountUpdated, pageId, pcOptimumAccount, provinceId, viewport]
  );
};

export default useSDMContextProps;
