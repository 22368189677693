import React, { FC } from 'react';

import { IDatepickerProps } from './datepicker.interface';
import styles from './datepicker.module.scss';

const Datepicker: FC<IDatepickerProps> = ({ selectedDateTime, refElement, onClick }) => (
  <div className={styles.datepickerContainer}>
    <label className={styles.datepickerLabel} htmlFor="previewDateTime">
      Select datetime to preview scheduled content
    </label>
    <p className={styles.datepickerSelectedDate}>DTime Selected: {new Date(selectedDateTime).toLocaleString()}</p>
    <div className={styles.datepicker}>
      <input
        ref={refElement}
        type="datetime-local"
        id="previewDateTime"
        name="previewDateTime"
        min={new Date().toISOString().slice(0, -8)}
      />
      <button onClick={onClick}>GO</button>
    </div>
  </div>
);

export default Datepicker;
