import { LOCALE, TO } from '../data/constants';

export function capitalize(word: string): string {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const capitalizeFirstLetter = (str: string): string => {
  const lCase = str.toLowerCase();
  return lCase.charAt(0).toUpperCase() + lCase.slice(1);
};

export const capitalizeEveryFirstLetter = (str: string): string => {
  //this regex splits the word on spaces, commas, and hyphens while keeping the delimeters
  //this should properly format 'compound names' like "Chun Li" or "O'Brien" or "Martin-Green"
  const words = str.split(/([\s'-])/);
  const formattedWords = words.map((word) => {
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return formattedWords.join('');
};

export const toLower = (str: string): string => str.toLowerCase();

export const replaceFirstDashWithToAndSpaces = (str: string, locale = 'en'): string => {
  if (locale === LOCALE.FR) return str.replace('-', ` ${TO.FR} `);
  return str.replace('-', ` ${TO.EN} `);
};

export const convertPxStrToNum = (str: string): number => {
  return str ? Number(str.replace(/px$/, '')) : 0;
};

export const formatPhoneNumber = (str: string): string => {
  if (!str || (str && str.length < 10)) {
    return '';
  }
  const phoneArr = str.split('');
  let newArr = '';
  for (let i = 0; i < phoneArr.length; i++) {
    if (i === 3 || i === 6) {
      newArr += ` ${phoneArr[i]}`;
    } else {
      newArr += phoneArr[i];
    }
  }
  return newArr;
};

export const formatPostalCode = (str: string): string => {
  if (!str || (str && str.length < 6)) {
    return '';
  }
  const postalCodeArr = str.split('');
  let newArr = '';
  for (let i = 0; i < postalCodeArr.length; i++) {
    if (i === 3) {
      newArr += ` ${postalCodeArr[i]}`;
    } else {
      newArr += postalCodeArr[i];
    }
  }
  return newArr;
};

/** normalize functions take raw user input and format it to a standard form internally */
export const normalizePostalCode = (rawPostalCode: string): string => {
  return (
    rawPostalCode.length === 7 ? rawPostalCode.slice(0, 3) + rawPostalCode.slice(4) : rawPostalCode
  ).toUpperCase();
};

export const normalizePhoneNumber = (rawPhoneNumber: any) => {
  return rawPhoneNumber.replace(/[- ()]/g, '');
};

// credit: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
export const camelize = (str: string): string => {
  str = str.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return str.substr(0, 1).toLowerCase() + str.substr(1);
};

export const getBrandLink = (description: string, brandName: string, brandUrl: string) => {
  const brandDescription = description.replace(/{brandName}/g, brandName.link(brandUrl));
  return brandDescription;
};

const SNOWPLOW_REJECT_CHARS = ['®', '™', ',', '-'];

export const convertStringToSnowplowCase = (text: string): string | null => {
  if (typeof text !== 'string') return null;
  const cleanText = text.replace(new RegExp(`(${SNOWPLOW_REJECT_CHARS.join('|')})`, 'gu'), '').trim();
  const spText = cleanText.toLowerCase().replace(/\s+/g, '-');
  return spText;
};

/**
 * A function to normalize a value string to be used for Snowplow.
 * This converts a string to only use lowercase printable ASCII characters with dashes.
 * See https://aticleworld.com/printable-ascii-characters-list/ for accepted characters.
 *
 * @param {string} text - the text to convert to a value for Snowplow
 * @returns {string}
 */
export const normalizeStringValueForSnowplow = (text: string): string | null => {
  if (typeof text !== 'string' || text === '') return null;
  const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Normalize French characters
  const cleanText = normalizedText.replace(new RegExp(`[^ -~]|[$]`, 'gu'), '').trim(); // Remove all non-ASCII characters and dollar signs
  const spText = cleanText.toLowerCase().replace(/\s+/g, '-'); //  Convert text to lowercase, replace all spaces with dashes
  return spText;
};
