import { Environment, Language } from '@pcid/web-sdk/build/index.js';
import { PcidAuth } from '@pcid/web-sdk';

export const pcidConfig = {
  clientId: process.env.NEXT_PUBLIC_PCID_CLIENT_ID || '',
  clientSecret: process.env.NEXT_PUBLIC_PCID_CLIENT_SECRET || '',
  scope: process.env.NEXT_PUBLIC_PCID_SCOPE || '',
  addOfflineAccessToScopes: true,
  stateParams: {
    relyingParty: 'sdm',
    intent: 'login',
    language: Language.EN,
    customProps: {
      name: 'sdm-frontshop',
    },
  },
  redirectUrl: process.env.NEXT_PUBLIC_PCID_REDIRECT_URL || '',
  environment: process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? Environment.PRODUCTION : Environment.LOWER,
  keepMeSignedIn: true,
  showIsThisYouPrompt: true,
  pkceFlow: true,
};

export const pcidAuth = new PcidAuth(pcidConfig);
