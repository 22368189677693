import { getSnowplowEnv, getSnowplowUserData } from '@/utils/analytics/analytics-utils';
import { SNOWPLOW } from '@/utils/analytics/constants';
import { KAMELEOON } from '@/utils/kameleoon/constants';
import { useEffect, useState } from 'react';

const { CUSTOM_DATA } = KAMELEOON;

const setKameleoonCustomData = (kameleoonQueue: any, Kameleoon: any) => {
  const snowplowUser = getSnowplowUserData();
  window.kameleoonQueue = window.kameleoonQueue || [];

  // Push the command to set the VisitorCode with snowplow domainUserId
  window.kameleoonQueue.push({
    level: 'IMMEDIATE',
    command: () => Kameleoon.API.Visitor.setVisitorCode(snowplowUser.domainUserId),
  });

  // to get the current snowplow env
  const snowplowEnv = getSnowplowEnv().toUpperCase() as 'QA' | 'DEV' | 'PROD';

  //Setting the custom data required by snowplow
  kameleoonQueue.push(function () {
    Kameleoon.API.Data.setCustomData(CUSTOM_DATA.SP_APP_ID, SNOWPLOW.APP_ID[snowplowEnv]);
    Kameleoon.API.Data.setCustomData(CUSTOM_DATA.SP_DOMAIN_USERID, snowplowUser.domainUserId);
    Kameleoon.API.Data.setCustomData(CUSTOM_DATA.SP_DOMAIN_SESSIONID, snowplowUser.sessionId);
  });
};

//Kameleoon is an experimentation tool, hook is used to send custom data to snowplow for tracking
export const useKameleoonEngine = () => {
  const [isSnowplowAndKamReady, setIsSnowplowAndKamReady] = useState(false);
  const [checkForKamEngine, setCheckForKamEngine] = useState(false);
  const [numCheckForKamEngine, setNumCheckForKamEngine] = useState(0);

  useEffect(() => {
    if (!isSnowplowAndKamReady) {
      if (typeof window !== 'undefined' && window.__SHL__ && window.Kameleoon) {
        setIsSnowplowAndKamReady(true);
        //allow to only check for Kameleoon object max 5 times to avoid infinite loop if kam script does not load
      } else if (numCheckForKamEngine < 4) {
        //delay 1 second to allow for Kameleoon to load
        setTimeout(() => setCheckForKamEngine(!checkForKamEngine), 1000);
        setNumCheckForKamEngine(numCheckForKamEngine + 1);
      }
    }
  }, [checkForKamEngine, isSnowplowAndKamReady, numCheckForKamEngine]);

  useEffect(() => {
    const { Kameleoon, kameleoonQueue } = window;
    if (isSnowplowAndKamReady) {
      setKameleoonCustomData(kameleoonQueue, Kameleoon);
    }
  }, [isSnowplowAndKamReady]);
};
