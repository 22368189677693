import { useState, useEffect } from 'react';
import { useStore } from '@/store/useStore';

export const useGetKameleoonFlag = (name: string): boolean => {
  const [hasFlag, setHasFlag] = useState<boolean>(false);
  const kamFlagsList = useStore(({ kamFlags }) => kamFlags);

  useEffect(() => {
    if (kamFlagsList && typeof kamFlagsList === 'object') {
      const isFlagActive = Object.keys(kamFlagsList).includes(name);
      setHasFlag(isFlagActive);
    } else {
      setHasFlag(false);
    }
  }, [name]);

  return hasFlag;
};

export default useGetKameleoonFlag;
