import { useCallback } from 'react';
import { MEDIA_PIXELS } from '@/utils/analytics/constants';

export const useTrackMediaPixelsAddToCart = () => {
  const trackMediaPixelsAddToCart = useCallback((cartCount: number) => {
    const { ADD_TO_BAG, ADD_TO_BAG_OPEN, CUSTOM_LINK } = MEDIA_PIXELS;
    setTimeout(() => {
      window?.dataLayer?.push({
        event: CUSTOM_LINK,
        linkName: cartCount > 1 ? ADD_TO_BAG : ADD_TO_BAG_OPEN,
      });
    }, 3000);
  }, []);

  return { trackMediaPixelsAddToCart };
};
