import { useEffect, useState } from 'react';

import { GEOLOCATION_CODES } from '@/data/constants';
import { toLower } from '@/utils';

export const useCookieConsent = (): boolean => {
  const [isQuebec, setIsQuebec] = useState(false);

  useEffect(() => {
    const getGeoLocation = () => {
      const {
        COUNTRY: { CA },
        PROVINCE: { QC },
      } = GEOLOCATION_CODES;

      if (typeof window !== 'undefined' && window?.OneTrust?.getGeolocationData) {
        const { country, state } = window.OneTrust.getGeolocationData();
        setIsQuebec(toLower(country) === CA && toLower(state) === QC);
      }
    };

    getGeoLocation();
  }, []);

  return isQuebec;
};
