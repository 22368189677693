import { DEVICES, SCREEN_SIZE_SUFFIX } from 'Data/constants';
import { useCallback, useEffect, useState } from 'react';
// import { size_mobile_max, size_newTablet_max, size_tablet_max } from 'styles/styles.scss';
import { convertPxStrToNum } from 'Utils/str-util';
import { debounce } from '../utils/utils';

const size_mobile_max = '650px';
const size_tablet_max = '950px';
//new/FS breakpoints:
const size_newTablet_max = '1023px';
/*
dev note:

our unit tests fail to import 'Styles' and variable like size_tablet_max
will appear in the tests as undefined but defined in the build
this is obviously a problem!!

This is a temp fix to include a backup hardcorded size for just jest
size_tablet_max will work during build time but during unit testing
size_tablet_max is undefined requires the backup 950px

see variables.scss which should be our source of truth for the breakpoints similar
to how we're now importing the colour tokens
*/

const sizeOldTabletMax = convertPxStrToNum(size_tablet_max || '950px');
const sizeNewTabletMax = convertPxStrToNum(size_newTablet_max || '1023px');
const sizeMobileMax = convertPxStrToNum(size_mobile_max || '650px');

const { DESKTOP, TABLET, MOBILE } = DEVICES;

export const getDeviceSuffix = (device: ValueOf<typeof DEVICES>): ValueOf<typeof SCREEN_SIZE_SUFFIX> => {
  const { DESKTOP, TABLET, MOBILE } = DEVICES;
  switch (device) {
    case DESKTOP:
      return SCREEN_SIZE_SUFFIX.DESKTOP;
    case TABLET:
      return SCREEN_SIZE_SUFFIX.TABLET;
    case MOBILE:
      return SCREEN_SIZE_SUFFIX.MOBILE;
    default:
      return SCREEN_SIZE_SUFFIX.DESKTOP;
  }
};

type WindowSize = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  currentDevice: ValueOf<typeof DEVICES>;
  deviceSuffix: ValueOf<typeof SCREEN_SIZE_SUFFIX>;
  currentDeviceWidth: string;
};

const mediaMatcher = typeof window === 'object' ? window.matchMedia('(pointer: coarse)') : undefined;

export const useWindowSize = ({ isModernized = true, debounceMilliSeconds = 1000 }): WindowSize => {
  const [currentDevice, setCurrentDevice] = useState<ValueOf<typeof DEVICES>>(DEVICES.DESKTOP);
  const [currentDeviceWidth, setCurrentDeviceWidth] = useState('');

  const getDevice = useCallback(() => {
    const windowWidth = window.innerWidth;
    setCurrentDeviceWidth(String(windowWidth));

    const oldTabletBreakpoint = sizeOldTabletMax;
    const newTabletBreakpoint = sizeNewTabletMax;
    const tabletMax = isModernized ? newTabletBreakpoint : oldTabletBreakpoint;
    const isTouchscreen = mediaMatcher?.matches;

    if (windowWidth > tabletMax && !isTouchscreen) {
      setCurrentDevice(DESKTOP);
    } else if (windowWidth > sizeMobileMax && (windowWidth <= tabletMax || isTouchscreen)) {
      setCurrentDevice(TABLET);
    } else setCurrentDevice(MOBILE);
  }, [isModernized]);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) {
      return;
    } else {
      getDevice();
      window.addEventListener('resize', debounce(getDevice, debounceMilliSeconds));
      mediaMatcher?.addEventListener('change', debounce(getDevice, debounceMilliSeconds));
    }

    return () => {
      window.removeEventListener('resize', debounce(getDevice, debounceMilliSeconds));
      mediaMatcher?.removeEventListener('change', debounce(getDevice, debounceMilliSeconds));
    };
  }, [getDevice, debounceMilliSeconds]);

  return {
    isMobile: currentDevice === MOBILE,
    isTablet: currentDevice === TABLET,
    isDesktop: currentDevice === DESKTOP,
    currentDevice,
    deviceSuffix: getDeviceSuffix(currentDevice),
    currentDeviceWidth,
  };
};
