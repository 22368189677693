import { IPCOptimumAccount, ISDMContextsProps } from '@ldp/sdm-contexts';

export type CustomerData = ISDMContextsProps['sdmCustomerProviderProps'];

/* Default customer data */
export const customerDataDefault: CustomerData = {
  adobeAudienceId: '',
  anonymous: true,
  customerId: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  language: null,
  cartId: null,
  pcOptimumAccount: null,
  pcInsiderMembership: null,
  postalCode: null,
  shopScanTermsAccepted: false,
  paymentServiceEnabled: false,
  customerEncryptedEmail: null,
  twoStepAuthEnabled: false,
  wholesaleClubMembership: null,
  preAuthExtraPercent: null,
  sameDayOrderPreAuthEnabledForBanner: true,
  sameDayOrderPreAuthEnabledForStore: false,
  setPcOptimumAccount: (pcOptimumAccount: IPCOptimumAccount) => pcOptimumAccount,
  provinceId: '',
  setProvinceId: (provinceId: string) => provinceId,
};

/** Mock Customer Data */
export const customerDataMock: CustomerData = {
  adobeAudienceId: '',
  anonymous: false,
  customerId: '7c3742c3-0ec2-4e34-9d5a-231617517827',
  firstName: 'John',
  lastName: 'Doe',
  email: 'john.doe@example.com',
  phone: {
    number: '647-555-5555',
    extension: '21',
  },
  language: 'English',
  cartId: '7c3742c3-0ec2-4e34-9d5a-231617517827',
  pcOptimumAccount: {
    memberId: '999999999',
    householdId: '999999999',
    walletId: '',
    points: {
      balance: 0,
      dollarsRedeemable: 0,
      dollarsRedeemedLifetime: 0,
    },
  },
  pcInsiderMembership: null,
  postalCode: 'H3R2J8',
  shopScanTermsAccepted: false,
  paymentServiceEnabled: false,
  customerEncryptedEmail: 'uKAh0w/eIPXRfqmr2nioCQ7S2e1CxAAJSAAp6DPU=',
  twoStepAuthEnabled: false,
  wholesaleClubMembership: null,
  preAuthExtraPercent: 25.0,
  sameDayOrderPreAuthEnabledForBanner: true,
  sameDayOrderPreAuthEnabledForStore: false,
  setPcOptimumAccount: (pcOptimumAccount: IPCOptimumAccount) => pcOptimumAccount,
  provinceId: 'ON',
  setProvinceId: (provinceId: string) => provinceId,
};
