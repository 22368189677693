/**
 * This code came from an existing script which looks like this:
 * ```ts
 * // Marketing Cloud Visitor Id - More info available at https://marketing.adobe.com/resources/help/en_US/mcvid/ and visitorAPI.js
 * var MCVISID = window.visitor._fields.MCMID
 * if (MCVISID != null) {
 *   // use SHA256 hashing to convert MCVISID(38-character ID) to a raw value (256-bit/32 bytes)
 *   var hashedMCVISID = sha256(MCVISID)
 *   var bytes = []
 *   // convert the hashed MSVISID hex string to a byte array, implementation as in https://developers.google.com/ads-data-hub/guides/cookie-matching
 *   for (let i = 0; i < hashedMCVISID.length; i += 2) {
 *     bytes.push(parseInt(hashedMCVISID.substr(i, 2), 16))
 *   }
 *   // truncate last 8 digits in byte array, in order to get it to 24 bytes
 *   var visIdBytes = bytes.slice(0, 24)
 *   // Encode the byte array in normal base64
 *   var visIdBase64 = window.btoa(String.fromCharCode(...new Uint8Array(visIdBytes)))
 *   // Ensure the visitorId uses web-safe Base64 encoding
 *   visIdBase64 = visIdBase64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")
 * }
 * ```
 */
const getHashedMCVISID = async (): Promise<string | undefined> => {
  // Marketing Cloud Visitor Id - More info available at https://marketing.adobe.com/resources/help/en_US/mcvid/ and visitorAPI.js
  const MCVISID = window.visitor?._fields.MCMID;
  if (!MCVISID) {
    console.warn('Failed to initialize GTM, reason: MCVISID is undefined');
    return undefined;
  } else if (!window.crypto?.subtle) {
    console.warn('Failed to initialize GTM, reason: SubtleCrypto API is unavailable');
    return undefined;
  }

  // use SHA256 hashing to convert MCVISID(38-character ID) to a raw value (256-bit/32 bytes)
  const encodedData = new TextEncoder().encode(MCVISID);
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);
  const bytes = new Uint8Array(hashBuffer).slice(0, 24);
  const base64Hash = window.btoa(String.fromCharCode(...bytes));

  // Ensure the visitorId uses web-safe Base64 encoding
  const base64UrlHash = base64Hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

  return base64UrlHash;
};

export const initializeGTM = async (): Promise<void> => {
  try {
    if (!window['dataLayer']) {
      window['dataLayer'] = [];
    }
    const hashedMCVISID = await getHashedMCVISID();
    if (hashedMCVISID) {
      window['dataLayer'].push({
        hashedMCVISID,
      });
    }
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  } catch (err) {
    console.error('Failed to initialize GTM, reason: ', err);
  }
};
