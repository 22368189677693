export const KAMELEOON = {
  SITE_CODE: 'iw31avyafw',
  CUSTOM_DATA: {
    SP_APP_ID: 'sp_app_id',
    SP_DOMAIN_USERID: 'sp_domain_userid',
    SP_DOMAIN_SESSIONID: 'sp_domain_sessionid',
  },
} as const;

export enum KameleoonDomains {
  Development = 'local.design-system.ca/',
  Staging = 'uat-shop.shoppersdrugmart.ca/',
  Production = 'shoppersdrugmart.ca/',
}

export enum KamErrors {
  Init = 'initialize',
  WarehouseKey = 'getWarehouseKey',
}

export const KAMELEOON_FEATURE_FLAGS = {
  ENABLE_SEARCH_TYPEAHEAD: 'enable_m6_search_typeahead',
};

export const KAMELEOON_CREDENTIALS = {
  CLIENT_ID: '26496-nova-quach-loblaw-ca',
  CLIENT_SECRET: 'DKQRzDrw_B4PqA48s99QC-ncRFL0saS2vzMqTAEUaPo',
} as const;
