import { banners } from '@/data/constants';
import { useEffect, useState } from 'react';

export const useDetermineBanner = () => {
  const [isPharmaprix, setIsPharmaprix] = useState<boolean>(false);

  useEffect(() => {
    const { hostname } = window.location;
    setIsPharmaprix(hostname.includes(banners.PHARMAPRIX) || hostname.includes(banners.PHX));
  }, []);

  return isPharmaprix;
};
